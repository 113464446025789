import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Input from "../../shared/Input/Input";
import {generateTimeSlots, getBookingDataForStudio, priceConvert} from "../../services/bookingPage";
import Select from "react-select";
import FormItem from "./FormItem";
import {ScheduleMeeting} from "react-schedule-meeting";
import Textarea from "../../shared/Textarea/Textarea";
import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import NcImage from "../../shared/NcImage/NcImage";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import RemoveButton from "../../components/Btns/RemoveButton";
import {imageSizeLimit, GA4PropertyId, defaultGalleryImage} from "../../contains/contants";
import {toast} from "react-toastify";
import StartRating from "../../components/StartRating/StartRating";
import GallerySlider from "../../components/GallerySlider/GallerySlider";
import moment from "moment-timezone";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import {createBooking} from "../../services/bookingPage";
import {useHistory, useParams} from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import ReactGA from "react-ga4";
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import bookingPage from "./BookingPage";

interface FormFields {
    selectedViewFields: any[];
    selectedService: {value: string, label: string} | null;
    selectedStaff: {value: string, label: string} | null;
    images: any[];
    description: string;
    date: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    telCode: {dialCode: string, countryCode: string};
    city: string;
    country: string;
    address: string;
    postalCode: string;
    switch: boolean
}

const initialValues: FormFields = {
    selectedViewFields: [],
    selectedService: null,
    selectedStaff: null,
    images: [],
    description: '',
    date: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    telCode: {dialCode: "", countryCode: ""},
    city: '',
    country: '',
    address: '',
    postalCode: '',
    switch: false,
};

function currentValidationSchema(step: number) {return StepOneValidation;}

  const StepOneValidation = Yup.object().shape({});

  const Step: React.FC<{step: number, next: () => void, isLastStep: boolean, isSubmitting: boolean, submitForm: () => void,}> = ({step, next, isLastStep, isSubmitting, submitForm}) => {
    const {values, setFieldValue } = useFormikContext<FormFields>();
    const navigate = useHistory();
    const [requestLoading, setRequestLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [serviceDuration, setServiceDuration] = useState<number>(0);
    const [serviceStaff, setServiceStaff] = useState([]);
    const [studioStaff, setStudioStaff] = useState<any[]>([]);
    const [timeSlotsArray, setTimeSlotsArray] = useState([]);
    const [bookingPageSettings, setBookingPageSettings] = useState<any>({
        isPublished: false,
        buttonText: "Book Now",
        studioName: "Studio Name",
        artistName: "Artist Name"
    })
    const params:any = useParams();

    const [username, setUsername] = useState<string>("");
    const [artistUsername, setArtistUsername] = useState<string>("");
    const location = useLocation();
    const locationArray = location.pathname.split("/")
    const formTopRef = React.useRef<HTMLDivElement | null>(null);

    const [artistFirstShown, setArtistFirstShown] = useState(false)


    useEffect(() => {
      (async function(){
        if (locationArray.indexOf("artist") > -1){
            await setArtistUsername(params.username);
        }else{
            await setUsername(params.username)
        }
      })()
    }, [])

    useEffect(() => {
        (async function(){
            if (username){
                ReactGA.initialize(GA4PropertyId);
                ReactGA.send({ hitType: "pageview", page: "/my-path", title: "/studios/" + username + "/book" });
            }

            if (username || artistUsername){
                let req = await getBookingDataForStudio(username, artistUsername);
                await setServices(req.services);
                await setServiceStaff(req.serviceStaff);
                await setStudioStaff(req.studioStaff);
                await setBookingPageSettings(req.bookingPageSettings);
            }
         })()
    }, [username, artistUsername])

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [artistError, setArtistError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [viewFieldsDropDownsErrors, setViewFieldsDropDownsErrors] = useState<string[]>([]);
    const [viewFieldsTextAreaError, setViewFieldsTextAreaError] = useState<boolean>(false)
    const [viewFieldsImagesError, setViewFieldsImagesError] = useState<boolean>(false)
    const [viewFieldsSwitchError, setViewFieldsSwitchError] = useState<boolean>(false)
      const handleNext = async () => {
        await setFirstNameError(false);
        await setLastNameError(false);
        await setEmailError(false);
        await setServiceError(false);
        await setArtistError(false);
        await setDateError(false);
        await setViewFieldsDropDownsErrors([]);
        await setViewFieldsTextAreaError(false);
        await setViewFieldsImagesError(false);
        await setViewFieldsSwitchError(false);

          if (step === 1){
              let hasError = false;
              if (!values.selectedService){
                  await setServiceError(true);
                  hasError = true;
              }

              if (values.selectedService && !values.selectedStaff){
                  await setArtistError(true);
                  hasError = true;
              }

              if (values.selectedService && values.selectedStaff && !values.date){
                  await setDateError(true);
                  hasError = true;
              }

              if (!hasError){
                  next();
                  window.scrollTo({ top: 0, behavior: "smooth" });
              }

          }
          if (step === 2 ){
              let hasError = false;

              //dropdowns validation
              let vfErrors:string[] = [];

              let service:any = services.filter((item:any) => {return item.id === values.selectedService?.value})[0];
              let vf = service.viewfields;

              let dropDownVF = vf.filter((item:any) => {return item.type === "dropdown"});

              for (const index in dropDownVF){
                  if (dropDownVF[index].isRequired){
                        let vfAnswer = values.selectedViewFields.filter((item:any) => {return item.id === dropDownVF[index].id});
                        if (vfAnswer.length === 0){
                            vfErrors.push( "vf" + dropDownVF[index].id);
                            hasError = true;
                        }
                  }
              }
              await setViewFieldsDropDownsErrors(vfErrors);

              //textArea validation
              let textAreaVF = vf.filter((item:any) => {return item.type === "textarea"})[0];
              if (textAreaVF.isRequired){
                  if (!values.description){
                      await setViewFieldsTextAreaError(true);
                      hasError = true
                  }
              }

              //images validation
              let imagesVF = vf.filter((item:any) => {return item.type === "upload"})[0];
              if (imagesVF.isRequired){
                  if (values.images.length === 0){
                      await setViewFieldsImagesError(true);
                      hasError = true
                  }
              }

              //switch validation
              let switchVF = vf.filter((item:any) => {return item.type === "switch"})[0];
              if (switchVF.isRequired){
                  if (!values.switch){
                      await setViewFieldsSwitchError(true);
                      hasError = true
                  }
              }

              if (!hasError){
                  next();
                  window.scrollTo({ top: 0, behavior: "smooth" });
              }

          }

          if (step === 3){
              let hasError = false;
              if (!values.firstName){
                  await setFirstNameError(true);
                  hasError = true;
              }

              if (!values.lastName){
                  await setLastNameError(true);
                  hasError = true;
              }

              const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
              if (!emailRegex.test(values.email)){
                  await setEmailError(true);
                  hasError = true;
              }

              if (!hasError){
                  next();
                  window.scrollTo({ top: 0, behavior: "smooth" });
              }
          }

      };

    const timeSlots = async (selectedService: any, selectedStaff: any) => {
        let staff:any = studioStaff.filter(function (obj:any){return obj.id === selectedStaff.value})[0];
        let service:any = services.filter(function (obj:any){return obj.id === selectedService.value})[0];

        let req = await generateTimeSlots(service.studioId, selectedService.value, selectedStaff.value, staff.individualBookingHours);
        await setTimeSlotsArray(req.slots)
    }

    const artists = (serviceId: string) => {

        if (!artistUsername){
            let serviceStaffIds = serviceStaff.filter(function (obj:any){return obj.serviceId === serviceId}).map(function (obj:any){return obj.studioStaffId});
            let selectedStaffForService = studioStaff.filter(function (obj:any){return serviceStaffIds.includes(obj.id)});

            return (<div>
                <Select
                    options={selectedStaffForService.map(function (obj: any){return {value: obj.id, label: obj.fullName}})}
                    name={'artists'}
                    value={values.selectedStaff}
                    onChange={async (res:any) => {
                        await setFieldValue("selectedStaff", res);
                        await timeSlots(values.selectedService, res);
                    }}
                    placeholder={'Select Artist...'}
                />
                { artistError && <div className="text-red-500 text-xs italic">Please select artist</div>}
            </div>)
        }
  };
      const handleOpenModal = (index: number) => {
          setIsOpen(true);
          setOpenFocusIndex(index);
      };
      const handleCloseModal = () => setIsOpen(false);

      const [isOpen, setIsOpen] = useState(false);
      const [openFocusIndex, setOpenFocusIndex] = useState(0);
      const removeButton = (imageId:string, setFunction:any, imagesArray:any) => {
          return (
              <RemoveButton
                  onClick={async() => {

                      let images = imagesArray.filter(function (obj:any){return obj.id !== imageId});
                      setFunction("images", images)

                  }}

                  className="bg-white absolute z-50 right-3 top-3 shadow-lg rounded-xl w-5 h-5"
                  color={'black'}
              />
          )
      }

      const renderTattooDetails = (serviceId:string, values:any, setFieldValue: any) => {
        let service:any = services.filter(function (obj:any){return obj.id === serviceId})[0];
        service.viewfields = service.viewfields.filter((item:any) => {return item.isVisible})

        return (
            <div ref={formTopRef}>
            <div className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" >
                <div className="space-y-8">
                    {/* ITEM */}
                    {service.viewfields.map((obj:any) =>{
                        if (obj.type === "dropdown"){
                            let value = null;
                            let vf = values.selectedViewFields.filter((item:any)=> {return item.id === obj.id})[0];

                            if (vf){
                                value = {value: vf.answer, label: vf.answer}
                            }
                            return (
                                <FormItem
                                    label={obj.isRequired ? obj.title + " (Required)" : obj.title + " (Optional)"}
                                    key={obj.id}
                                    //desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                                >
                                    <Select
                                        options={obj.options.map(function (item: any){return {value: item, label: item}})}
                                        name={"viewfields" + obj.id}
                                        value={value}
                                        onChange={(res:any) => {
                                            let viewFields = values.selectedViewFields;

                                            let index = viewFields.findIndex((v:any) => v.id === obj.id);
                                            let field = {
                                                id: obj.id,
                                                title: obj.title,
                                                answer: res.label,
                                                isRequired: obj.isRequired
                                            }

                                            if (index >= 0){
                                                viewFields[index] = field
                                            }else{
                                                viewFields.push(field)
                                            }

                                            setFieldValue("selectedViewFields", viewFields);
                                        }}
                                        placeholder={"Select... "}
                                    />
                                    {viewFieldsDropDownsErrors.indexOf("vf" + obj.id) > -1 && <div className="text-red-500 text-xs italic">Required!</div>}
                                </FormItem>
                            )
                        }else if(obj.type === "textarea"){
                            return (
                                <FormItem
                                    key={obj.id}
                                    label={obj.isRequired ? obj.title + " (Required)" : obj.title + " (Optional)"}>
                                    <Textarea placeholder="..." onChange={(val) => {
                                        setFieldValue("description", val.target.value)
                                    }} rows={14} value={values.description} />
                                    {viewFieldsTextAreaError && <div className="text-red-500 text-xs italic">Please add description.</div>}
                                </FormItem>
                            )
                        }else if(obj.type === "upload"){
                            return (
                                <div key={obj.id}>
                                    <span className="text-lg font-semibold">{obj.isRequired ? obj.title + " (Required)" : obj.title + " (Optional)"}</span>
                                    <div >
                                        <div className="mt-5 mb-5">
                                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-neutral-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        ></path>
                                                    </svg>
                                                    <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input
                                                                id="file-upload"
                                                                name="file-upload"
                                                                type="file"
                                                                accept="image/*"
                                                                multiple
                                                                className="sr-only"
                                                                onChange={async (file:any) => {
                                                                        let imagesArray = [...values.images];
                                                                        let notUploaded = 0;

                                                                        for (let i = 0; i < file.target.files.length; i++){
                                                                            if (file.target.files[i].size < imageSizeLimit * 1024 * 1024){  //15MB
                                                                                file.target.files[i].id = file.target.files[i].name
                                                                                imagesArray.push(file.target.files[i])
                                                                            }else{
                                                                                notUploaded++;
                                                                            }
                                                                        }

                                                                        if (notUploaded > 0){
                                                                            toast.warn("Warning: image size should be less than " + imageSizeLimit + "MB. Not uploaded photos: " + notUploaded, {
                                                                                position: toast.POSITION.TOP_CENTER
                                                                            });
                                                                        }

                                                                        setFieldValue("images", imagesArray)

                                                                }}
                                                            />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                                        PNG, JPG, GIF up to 15MB
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { values.images.length > 0 ?
                                       <div>
                                        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                                            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                                                <div
                                                    className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"

                                                >
                                                    <NcImage
                                                        containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                                        src={values.images.length > 0 ? URL.createObjectURL(values.images[0]) : undefined}
                                                    />
                                                    <div
                                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                                        onClick={() => handleOpenModal(0)}
                                                    />

                                                    <div>
                                                        {removeButton(values.images[0].id, setFieldValue, values.images)}
                                                    </div>
                                                </div>
                                                {values.images.filter((_:any, i:any) => i >= 1 && i < values.images.length).map((item:any, index:any) => (
                                                    <div
                                                        key={index}
                                                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                                            index >= 3 ? "hidden sm:block" : ""
                                                        }`}
                                                    >
                                                        <NcImage
                                                            containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                                            className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                                            src={URL.createObjectURL(item)}
                                                        />

                                                        {/* OVERLAY */}
                                                        <div
                                                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                                            onClick={() => handleOpenModal(index + 1)}
                                                        />
                                                        <div>
                                                            {removeButton(item.id, setFieldValue, values.images)}
                                                        </div>
                                                    </div>
                                                ))}

                                                <div
                                                    className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                                    onClick={() => handleOpenModal(0)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={1.5}
                                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                                        />
                                                    </svg>
                                                    <span className="ml-2 text-neutral-800 text-sm font-medium">
                                                      Show all photos
                                                    </span>
                                                </div>
                                            </div>
                                        </header>

                                        <ModalPhotos
                                            imgs={values.images.map(function (obj:any){return URL.createObjectURL(obj)})}
                                            isOpen={isOpen}
                                            onClose={handleCloseModal}
                                            initFocus={openFocusIndex}
                                            uniqueClassName="nc-StudioProfilePage-modalPhotos"
                                        />
                                    </div> : null}
                                    {viewFieldsImagesError && <div className="text-red-500 text-xs italic">Please upload photo/s.</div>}
                                </div>
                            )
                        }else if(obj.type === "switch"){
                            return (
                                <FormItem  label={""} key={obj.id}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 3}}>
                                            <span className="text-lg font-semibold">{obj.isRequired ? obj.title + " (Required)" : obj.title + " (Optional)"}</span>

                                        </div>
                                        <div style={{flex: 1, textAlign: "right"}}>
                                            <Switch  onChange={(val) => {
                                                setFieldValue("switch", val)
                                            }} checked={values.switch}/>
                                        </div>
                                    </div>
                                    {viewFieldsSwitchError && <div className="text-red-500 text-xs italic">Required!</div>}
                                </FormItem>
                            )
                        }
                    })}
                </div>
            </div>
            </div>
        )
    };

      const renderCustomerDetails = () => {
          const root = document.querySelector("html");
          if (!root) return;

          return (
            <div className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" >
                <h2 className="text-2xl font-semibold">Your personal details</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* FORM */}
                <div className="space-y-8">
                    {/* ITEM */}
                    <FormItem label="First name">
                        <Input placeholder="First name" value={values.firstName} onChange={(val) => {
                            setFieldValue("firstName", val.target.value)
                        }} />
                        {firstNameError && <div className="text-red-500 text-xs italic">First name is required</div>}
                    </FormItem>
                    <FormItem label="Last name" >
                        <Input placeholder={"Last name"} value={values.lastName} onChange={(val) => {
                            setFieldValue("lastName", val.target.value)
                        }}/>
                        {lastNameError && <div className="text-red-500 text-xs italic">Last name is required</div>}
                    </FormItem>
                    <FormItem label="Email address">
                        <Input placeholder={"Email address"} value={values.email} onChange={(val) => {
                            setFieldValue("email", val.target.value)
                        }}/>
                        {emailError && <div className="text-red-500 text-xs italic">Please insert valid email</div>}
                    </FormItem>
                    <FormItem label="Phone number">
                        <PhoneInput
                            country={"us"}
                            inputStyle={{
                                width: "100%",
                                height: 44,
                                borderRadius: "1rem",
                                borderColor: root.classList.contains("dark") ? "rgb(53,63,79)" : "rgb(229 229 229)" ,
                                backgroundColor: root.classList.contains("dark") ? "rgb(17 24 39)" : "white"
                            }}
                            buttonStyle={{
                                borderTopLeftRadius: "1rem",
                                borderBottomLeftRadius: "1rem",
                                borderColor: root.classList.contains("dark")? "rgb(53,63,79)" : "rgb(229 229 229)" ,
                                backgroundColor: root.classList.contains("dark") ? "rgb(17 24 39)" : "white"
                            }}
                            dropdownStyle={{
                                backgroundColor: root.classList.contains("dark") ? "rgb(17 24 39)" : "white"
                            }}
                            placeholder="Enter phone number"
                            value={values.phone ? '+' + values.telCode.dialCode + values.phone : ''}
                            onChange={(value, data:any, e, formattedValue) => {

                                let telCode = {};
                                let phone = '';

                                if (data && data.dialCode){
                                    telCode = {
                                        dialCode: data.dialCode,
                                        countryCode: data.countryCode
                                    }

                                    phone = value.slice(data.dialCode.length)
                                }

                                setFieldValue('telCode', telCode);
                                setFieldValue('phone', phone);
                            }}
                        />
                    </FormItem>
                </div>
            </div>
        )
    };

      const renderBookingSummary = (values:any) => {
          let service:any = services.filter(function (obj:any){return obj.id === values.selectedService.value})[0];
          return (
              <div className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" >
                  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">

                      {/* ------------------------ */}
                      <div className="space-y-6">
                          <h3 className="text-2xl font-semibold">Your booking</h3>
                          <div className="flex flex-col sm:flex-row sm:items-center">
                              <div className="flex-shrink-0 w-full sm:w-40">
                                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                                      <GallerySlider
                                          uniqueID={`preview_gallery`}
                                          ratioClass="aspect-w-4 aspect-h-3 "
                                          galleryImgs={values.images.length > 0 ? values.images.map(function (obj:any){return URL.createObjectURL(obj)}) : [defaultGalleryImage]}
                                      />
                                  </div>
                              </div>
                              <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                                  <div>
                                      <span className="text-base sm:text-lg font-medium mt-1 block">
                                        {username}
                                      </span>
                                  </div>
                                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                                    {values.selectedService.label}
                                  </span>
                                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                                  <StartRating />
                              </div>
                          </div>
                          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                              <div className="flex-1 p-5 flex space-x-4">
                                  <svg
                                      className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                          d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                                          stroke="#D1D5DB"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                      />
                                  </svg>

                                  <div className="flex flex-col">
                                      <span className="text-sm text-neutral-400">Date</span>
                                      <span className="mt-1.5 text-lg font-semibold">
                                        {moment(values.date).format("DD MMMM YYYY HH:mm")}
                                      </span>
                                  </div>
                              </div>
                              <div className="flex-1 p-5 flex space-x-4">
                                  <svg
                                      className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                          d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                                          stroke="#D1D5DB"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                      />
                                  </svg>

                                  <div className="flex flex-col">
                                      <span className="text-sm text-neutral-400">Artist</span>
                                      <span className="mt-1.5 text-lg font-semibold">{values.selectedStaff.label}</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      {/* ------------------------ */}
                      <div className="space-y-6">
                          <h3 className="text-2xl font-semibold">Booking detail</h3>
                          <div className="flex flex-col space-y-4">
                              <div className="flex text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Date</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {moment(values.date).format("DD MMMM YYYY HH:mm")}
                                  </span>
                              </div>
                              <div className="flex text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Price</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {priceConvert(service.priceType, service.priceValue)}
                                  </span>
                              </div>
                              {values.selectedViewFields.map(function (obj:any){
                                  return ( <div key={obj.id} className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                      <span className="flex-1">{obj.title}</span>
                                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                        {obj.answer}
                                      </span>
                                  </div>)
                              })}
                              {values.description ? <div className="flex text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Notes</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {values.description}
                                  </span>
                              </div> : null}
                          </div>
                      </div>
                      {/* ------------------------ */}
                      <div className="space-y-6">
                          <h3 className="text-2xl font-semibold">Your info</h3>
                          <div className="flex flex-col space-y-4">
                              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Your name</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {values.firstName + " " + values.lastName}
                                  </span>
                              </div>
                              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Email address</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {values.email}
                                  </span>
                              </div>
                              {values.phone ? <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                  <span className="flex-1">Email address</span>
                                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {"+" + values.telCode.dialCode + " " + values.phone}
                                  </span>
                              </div> : null}
                          </div>
                      </div>
                      {/*<div>*/}
                      {/*    <ButtonPrimary onClick={async () => {*/}
                      {/*        let data = {*/}
                      {/*            serviceId: values.selectedService.value,*/}
                      {/*            serviceDuration: service.duration,*/}
                      {/*            studioStaffId: values.selectedStaff.value,*/}
                      {/*            date: moment(values.date).format("YYYY-MMM-DD"),*/}
                      {/*            hour: moment(values.date).format("HH:mm"),*/}
                      {/*            viewFields: values.selectedViewFields.map(function (item:any){*/}
                      {/*                return {title: item.title, answer: item.answer}*/}
                      {/*            }),*/}
                      {/*            description: values.description,*/}
                      {/*            firstName: values.firstName,*/}
                      {/*            lastName: values.lastName,*/}
                      {/*            email: values.email,*/}
                      {/*            telCode: values.telCode,*/}
                      {/*            phone: values.phone,*/}
                      {/*            images: values.images*/}
                      {/*        }*/}
                      {/*        await setRequestLoading(true)*/}
                      {/*        let req = await createBooking(data)*/}

                      {/*        if (req.response === "success"){*/}
                      {/*            navigate.push("/booking/" + req.guid + "/verify", data)*/}
                      {/*        }else if(req.response === "hourNotFree"){*/}
                      {/*            toast.warn("Selected hour is booked. Please select another and try again", {*/}
                      {/*                position: toast.POSITION.TOP_CENTER*/}
                      {/*            });*/}
                      {/*        }else{*/}
                      {/*            toast.error("An error occurred. Please refresh and try again", {*/}
                      {/*                position: toast.POSITION.TOP_CENTER*/}
                      {/*            });*/}
                      {/*        }*/}
                      {/*        await setRequestLoading(false)*/}
                      {/*    }}>Book Now</ButtonPrimary>*/}
                      {/*</div>*/}
                  </div>
              </div>
          )
      };

      return (
      <div>
          {requestLoading ? <LoadingSpinner /> : null}

          {bookingPageSettings.isPublished ? <div>
              <h1 className="text-2xl font-semibold">Book Your Session with {bookingPageSettings.studioName ? bookingPageSettings.studioName : bookingPageSettings.artistName}</h1>
              {step === 1 && (
                  <div className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32">
                      <h2 className="text-2xl font-semibold">Details</h2>
                      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                      <div className="space-y-8">
                          <div>
                              <Select
                                  options={services ? services.map(function (obj: any) {
                                      return {value: obj.id, label: obj.name + " - " + obj.durationFormat}
                                  }) : []}
                                  name={'services'}
                                  value={values.selectedService}
                                  onChange={async (res: any) => {
                                      let service: any = services.filter(function (obj: any) {
                                          return obj.id === res.value
                                      })[0];

                                      await setFieldValue("selectedService", res);
                                      await setServiceDuration(service.duration);
                                      await setFieldValue("selectedViewFields", []);
                                      await setFieldValue("description", "");
                                      await setFieldValue("images", []);
                                      await setFieldValue("switch", false);

                                      if (values.selectedStaff && !artistUsername) {
                                          await setFieldValue("selectedStaff", null);
                                          await setTimeSlotsArray([]);
                                      }

                                      if (artistUsername){
                                          await setFieldValue("selectedStaff", {value: studioStaff[0].id, label: studioStaff[0].fullName});
                                          await timeSlots(res, {value: studioStaff[0].id, label: studioStaff[0].fullName});
                                      }
                                  }}
                                  placeholder={'Select Service...'}></Select>
                              {serviceError && <div className="text-red-500 text-xs italic">Please select service</div>}

                          </div>
                          <div>
                              {values.selectedService ? artists(values.selectedService.value) : null}
                          </div>
                          {values.selectedService && values.selectedStaff ? <div>
                              <div>
                                  <h2>Choose date and time</h2>
                              </div>
                              <ScheduleMeeting
                                  borderRadius={10}
                                  primaryColor="#3f5b85"
                                  eventDurationInMinutes={serviceDuration}
                                  availableTimeslots={timeSlotsArray}
                                  onStartTimeSelect={(date: any) => {
                                      setFieldValue("date", date.startTime.toString())
                                  }}
                              />
                              {dateError && <div className="text-red-500 text-xs italic">Please select date</div>}
                          </div> : null}
                      </div>
                  </div>
              )}
              {step === 2 && (
                  <div>
                      {values.selectedService ? renderTattooDetails(values.selectedService.value, values, setFieldValue) : []}
                  </div>
              )}
              {step === 3 && (
                  <div>
                      {renderCustomerDetails()}
                  </div>
              )}
              {step === 4 && (
                  <div>{renderBookingSummary(values)}</div>
              )}
              <div className="absolute bottom-0 right-0 mb-4 mr-4">
                  {!isLastStep ? (
                      <ButtonPrimary type="button" onClick={handleNext}>
                          Next
                      </ButtonPrimary>
                  ) : (
                      <ButtonPrimary onClick={async () => {
                          let data = {
                              serviceId: values.selectedService?.value,
                              serviceDuration: serviceDuration,
                              studioStaffId: values.selectedStaff?.value,
                              date: moment(values.date).format("YYYY-MMM-DD"),
                              hour: moment(values.date).format("HH:mm"),
                              viewFields: values.selectedViewFields.map(function (item: any) {
                                  return {title: item.title, answer: item.answer}
                              }),
                              description: values.description,
                              firstName: values.firstName,
                              lastName: values.lastName,
                              email: values.email,
                              telCode: values.telCode,
                              phone: values.phone,
                              images: values.images
                          }
                          await setRequestLoading(true)
                          let req = await createBooking(data)

                          if (req.response === "success") {
                              navigate.push("/booking/" + req.guid + "/verify", data)
                          } else if (req.response === "hourNotFree") {
                              toast.warn("Selected hour is booked. Please select another and try again", {
                                  position: toast.POSITION.TOP_CENTER
                              });
                          } else {
                              toast.error("An error occurred. Please refresh and try again", {
                                  position: toast.POSITION.TOP_CENTER
                              });
                          }
                          await setRequestLoading(false)
                      }}>{bookingPageSettings.buttonText}</ButtonPrimary>
                  )}
              </div>
          </div> : <div>Booking page is temporary unavailable </div>}


      </div>
      );
  };

const MultiStepForm: React.FC = () => {


      const [step, setStep] = useState(1);
      const next = () => setStep((prev) => prev + 1);
      const previous = () => setStep((prev) => prev - 1);

      const handleSubmit = (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
      };

      return (
        <div>
          {/* <h1>Booking</h1> */}
          <Formik
              initialValues={initialValues}
              validationSchema={currentValidationSchema(step)}
              onSubmit={handleSubmit}
              validateOnMount={true}
            >
              {({ isSubmitting, handleSubmit, submitForm }) => (
                 <div className="px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" >
                 <div className="relative">
                <Form>
                  <Step step={step} next={next} isLastStep={step === 4} isSubmitting={isSubmitting} submitForm={submitForm} />

                  {step > 1 && (
                     <ButtonSecondary type="button" onClick={previous} className="absolute bottom-0 left-0 mb-4 ml-4">
                       Previous
                     </ButtonSecondary>
                  )}

              </Form>
              </div>
              </div>
            )}
          </Formik>
        </div>
      );
    };

export default MultiStepForm;