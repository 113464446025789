import {setUser, setToken, setIsLoggedIn, logoutUser, setIsTattooArtist, setArtist} from './auth-slice'
import authService from '../services/authService'
import {updateUserProfile} from '../services/userService'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from './index'
import {AuthModel, User} from "../models/auth";
import {toast} from "react-toastify";

//export const authActions=authSlice.actions

export const login=(emailAddress:string, password:string, navigate: any, url: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            const response:{data: AuthModel, response: string, numberOfFails: number | null} =await authService.login(emailAddress, password);

            if(response.response === "success"){
              dispatch(setUser(response.data.user));
              dispatch(setArtist(response.data.artist));
              dispatch(setToken(response.data.token));
              dispatch(setIsLoggedIn(response.data.isLoggedIn));
              dispatch(setIsTattooArtist(response.data.isTattooArtist))
              navigate.push(url)
            }else if(response.response === "badCombo"){
              toast.error("The email or password is incorrect", {
                position: toast.POSITION.TOP_CENTER
              });
              return {numberOfFails: response.numberOfFails};
            }else{
              toast.error("Login error", {
                position: toast.POSITION.TOP_CENTER
              });
            }

    }
}
export const loginInApp=(token: string, navigate: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            const response:{data: AuthModel, response: string } = await authService.loginFromInAppBrowser(token);

            if(response.response === "success"){
              dispatch(setUser(response.data.user));
              dispatch(setArtist(response.data.artist));
              dispatch(setToken(token));
              dispatch(setIsLoggedIn(response.data.isLoggedIn));
              dispatch(setIsTattooArtist(response.data.isTattooArtist))
              navigate.push("/list-tattoo-contests")
            }else if(response.response === "badCombo"){
              toast.error("The email or password is incorrect", {
                position: toast.POSITION.TOP_CENTER
              });
            }else{
              toast.error("Login error", {
                position: toast.POSITION.TOP_CENTER
              });
            }

    }
}
export const updateUser=(name:string, emailAddress:string, userId: number):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            let token = getState().auth.token;
            const req:{response: string, user: User}=await updateUserProfile(name, emailAddress, userId, token);

            if (req.response === "success"){
                dispatch(setUser(req.user));
                toast.success("Your profile was successfully updated", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else if(req.response === "emailAlreadyInUse"){
                toast.error("Email already in use.", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else{
                toast.error("An error occurred. Please try again", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
    }

}

export const register=(fullName:string, emailAddress:string, password:string, confirmPassword:string, navigate: any, url: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            const response:{data: AuthModel, response: string} =await authService.register(fullName,emailAddress, password, confirmPassword);

            if(response.response === "success"){
                dispatch(setUser(response.data.user));
                dispatch(setToken(response.data.token));
                dispatch(setIsLoggedIn(response.data.isLoggedIn));

                toast.success("You have been successfully registered.", {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate.push(url)
            }else if(response.response === "emailAlreadyInUse"){
                toast.error("Email is already in use", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else{
                toast.error("Registration error", {
                    position: toast.POSITION.TOP_CENTER
                });
            }

    }

}


export const authWithGoogle=(credential: string, navigate: any, url: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            const response:{data: AuthModel, response: string} =await authService.authWithGoogle(credential);

            if(response.response === "success"){
                dispatch(setUser(response.data.user));
                dispatch(setArtist(response.data.artist));
                dispatch(setToken(response.data.token));
                dispatch(setIsLoggedIn(response.data.isLoggedIn));
                dispatch(setIsTattooArtist(response.data.isTattooArtist));

                toast.success("You have been successfully authenticated.", {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate.push(url)
            }else{
                toast.error("Registration error", {
                    position: toast.POSITION.TOP_CENTER
                });
            }

    }

}

export const logout=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
            dispatch(logoutUser(false));
    }

}
